import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const AboutUs = () => (
  <>
    <Seo title="A Propos" />
    <div className="mx-auto py-16 px-4 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:py-20 lg:px-8">
      <div className="max-w-xl mb-10 sm:text-center md:mx-auto md:mb-12 lg:max-w-2xl">
        <div>
          <p className="rounded-full font-semibold py-px bg-teal-accent-400 text-xs mb-4 tracking-wider text-teal-900 inline-block uppercase">
            Maxhel.fr
          </p>
        </div>
        <h2 className="font-sans font-bold max-w-lg mb-6 leading-none tracking-tight text-3xl text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="-mt-8 -ml-20 top-0 left-0 text-blue-gray-100 w-32 z-0 absolute hidden sm:block lg:-mt-10 lg:-ml-28 lg:w-32"
            >
              <defs>
                <pattern
                  id="2feffae2-9edf-414e-ab8c-f0e6396a0fc1"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#2feffae2-9edf-414e-ab8c-f0e6396a0fc1)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Le</span>
          </span>{' '}
          fournisseur de textiles sportifs de haute qualité technique à des prix décents
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Nous sommes faciliteurs de l'optimisation de votre trésorerie.
        </p>
      </div>
      <div className="max-w-screen-lg grid gap-8 sm:mx-auto lg:grid-cols-2">
        <div className="grid gap-7 grid-cols-2">
          <StaticImage
            src="../images/about/about.webp"
            layout="fullWidth"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="card"
            className="rounded object-cover h-56 shadow-lg w-full col-span-2"
          />

          <StaticImage
            src="../images/about/about2.webp"
            layout="fullWidth"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="card"
            className="rounded object-cover h-48 shadow-lg w-full"
          />

          <StaticImage
            src="../images/about/about3.webp"
            layout="fullWidth"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="card"
            className="rounded object-cover h-48 shadow-lg w-full"
          />
        </div>
        <div className="flex flex-col justify-start">
          <div className="border-b space-y-2 mb-4 pb-4">
            <h6 className="font-semibold mb-2 leading-5">
              À propos
            </h6>
            <ul className="list-disc list-inside space-y-1 text-sm text-gray-900">
              <li> Convertissez vos inspirations, créer vos textiles et accessoires à votre marque</li>
              <li> Maxhel est à vos côtés que vous souhaitiez équiper les membres de vos associations, créer votre marque, mettre en place des manifestations ou accentuer la visibilité de vos entreprises ou collectivités.</li>
              <li> Une idée, et Maxhel se charge du reste.</li>
            </ul>
          </div>
          <div className="border-b mb-4 pb-4">
            <h6 className="font-semibold mb-2 leading-5">
              Le déclic
            </h6>

            <ul className="list-disc list-inside space-y-1 text-sm text-gray-900">
              <li> Faisant partie d’associations sportives, nous avons vite constater la difficulté à bien s’équiper à des tarifs intéressants.</li>
              <li> Notre idée, aller à la source et négocier au mieux.</li>
              <li> Créer un véritable partenariat et une confiance avec des fournisseurs sérieux et qualifiés.</li>
            </ul>
          </div>

          <div>
            <h6 className="font-semibold mb-2 leading-5">
              Le Fondateur
            </h6>
            <p className="text-sm text-gray-900">
              Laurent Urgin dispose de <span className="font-semibold">22 années d’expérience en relation client</span>, de <span className="font-semibold">8 années de pratique du Sourcing</span> dans de de nombreux domaines d’activités (Construction, Produits capillaires, Équipements et Ameublement).
              Hautement qualifié en vente, stratégie marketing, négociation.
              Diplômé d’un Grade Master spécialisé en Management, Marketing, Stratégie et Finances.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default AboutUs
